import React from "react";
import ExperienceContainer from "./experience";
import Dark from "../images/dark.png";

function Fexperience() {
  const jobData = [
    {
      jobName: "Remitn",
      jobRole: "Scrum Master - BA",
      roles: [
        {
          role: "Facilitated the creation of comprehensive digital transformation plans for web and mobile platforms, leading to a successful product launch with 100% adherence to project timelines.",
          image: Dark,
        },
        {
          role: "Coached the development team on Agile principles, facilitated scrum ceremonies, and removed roadblocks, leading to a 100% sprint goal success rate, on-time delivery, and high client satisfaction.",
          image: Dark,
        },
        {
          role: "Conducted detailed project analysis and feature development, constantly refining the product backlog with Agile best practices, which ensured 100% adherence to product quality and project timelines.",
          image: Dark,
        },
      ],
    },
    {
      jobName: "Aella",
      jobRole: "Mobile Engineer",
      roles: [
        {
          role: "Refactored the entire mobile codebase using Flutter with an Agile framework, implementing Agile tools (Jira, Azure DevOps), resulting in a remarkable 25% increase in the customer base and a 0.5-rating improvement",
          image: Dark,
        },
        {
          role: "Analyzed user data to identify performance issues and improve business processes, notably managing sprint backlog tasks, and monitoring key performance indicators, resulting in 100% achievement of product backlog commitments and a 20% boost in daily active users (DAUs)",
          image: Dark,
        },
      ],
    },
    {
      jobName: "Flutterwave",
      jobRole: "Software Engineer",
      roles: [
        {
          role: "Reviewed the implementation of Forter, LexisNexis, and Unit 21 systems, demonstrating a 99.99% success rate in maintaining financial transaction integrity through thorough investigations & data analysis.",
          image: Dark,
        },
        {
          role: "Supported cross-team integration as the communication channel into the compliance team’s Veriff integration and via the creation, delivery, and maintenance of technical documentation.",
          image: Dark,
        },
      ],
    },
    {
      jobName: "Verraki",
      jobRole: "Solutions Analyst",
      roles: [
        {
          role: "Played a key role in the development of an RPA invoice project for a multinational company, ensuring 100% compliance with regulatory standards and timelines through iterative sprints and continuous feedback.",
          image: Dark,
        },
        {
          role: "Prepared detailed reports and presentations on project status and outcomes, resulting in 100% stakeholder satisfaction and an improvement in decision-making processes.",
          image: Dark,
        },
      ],
    },
  ];

  return <ExperienceContainer jobData={jobData} />;
}

export default Fexperience;
